import {PropTypes} from 'prop-types';
import React from 'react';

const Link = ({url, text}) => {
    return (
        <a href={url} target="_blank" rel="noreferrer">
            {text}
        </a>
    );
};

Link.propTypes = {
    url: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
};

export default Link;
