import React, {useState} from 'react';
import styles from './About.module.scss';
import classNames from 'classnames/bind';
import overlay from 'images/video.jpg';

const cx = classNames.bind(styles);

const About = () => {
    const [faded, setFaded] = useState(false);
    const fadeout = (e) => setFaded(!faded);

    return <div className={cx('about', 'center')}>
        <h1>About Med Vue</h1>
        <div className={cx(faded ? 'faded' : 'not-faded')}>
            <div className={cx('vidFrame', 'play')}>
                <img width="640" src={overlay} onClick={(e) => fadeout(e)} />
                <iframe src="https://player.vimeo.com/video/510345999?app_id=122963"
                    width="640" height="360" frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture" allowFullScreen title="Med Vue"></iframe>
            </div>
            <p>
                Med-Vue is the world’s first dedicated Media Content Resource for Healthcare Press and Journalists.
            </p>
            <p>
                Featuring every FDA approved drug since 2018, all content is official, up-to-date
                and cleared for media use.
            </p>
            <p>
                As the use of multimedia grows, Med-Vue  provides a trusted touchpoint between Pharma
                and Media to eliminate disinformation and improve authentic content flow.
            </p>
        </div>
    </div>;
};

export default About;
