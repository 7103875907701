import classNames from 'classnames/bind';
import {PropTypes} from 'prop-types';
import React from 'react';
import Link from './Link';
import styles from './ResultRow.module.scss';

const cx = classNames.bind(styles);

const ResultRow = (result) => {
    const {
        name,
        content_page_url: contentPageUrl,
        approval,
        approval_year: year,
        project_url: projectUrl,
        manufacturer,
        client_url: clientUrl,
        treatment,
        type,
    } = result.result;

    return (
        <div className={cx('result-row')}>
            <div>
                <Link url={contentPageUrl} text={name} />
            </div>
            <div>
                <Link url={projectUrl} text={`${year} (${approval})`} />
            </div>
            <div>
                <Link url={clientUrl} text={manufacturer} />
            </div>
            <div>{treatment}</div>
            <div>{type}</div>
        </div>
    );
};

ResultRow.propTypes = {
    result: PropTypes.shape().isRequired,
};

export default ResultRow;
