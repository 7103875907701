import useMediaQuery from '@material-ui/core/useMediaQuery';
import classNames from 'classnames/bind';
import DrugsCarousel from 'components/DrugsCarousel';
import RssFeed from 'components/rss-feed/RssFeed';
import SearchForm from 'components/search-form';
import Latest from 'components/search-form/components/Latest';
import SearchResults from 'components/search-results';
import useUrlParams from 'hooks/useUrlParams';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import styles from './Search.module.scss';

const cx = classNames.bind(styles);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetInTouch = () => {
    return (
        <div className={cx('get-in-touch')}>
            <h3>Get in touch</h3>
            <p>
                <a href="mailto:support@med-vue.co.uk">support@med-vue.co.uk</a>
            </p>
        </div>
    );
};

const Search = () => {
    const isMobile = useMediaQuery('(max-width:1050px)');
    const form = useForm();
    const [isShowSearch, setShowSearch] = useState(true);
    const [currentSearch, setCurrentSearch] = useState({});
    const {updateParams} = useUrlParams(form, setCurrentSearch, setShowSearch);

    const [queryState, setQueryState] = useState({
        data: [],
        isLoading: true,
    });

    useEffect(() => {
        const url = SERVER_URL + '/notify/medvue?password=asdf234';
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                setQueryState({
                    data: result.data,
                    isLoading: false,
                });
            });
    }, []);

    const {data, isLoading} = queryState;

    const handleSubmit = (data) => {
        updateParams(data);
        setShowSearch(false);
    };

    const filter = (a) => a && a.trim() !== '';
    const sort = (a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'});
    const dateSort = (a, b) => {
        return Date.parse(a.approval) < Date.parse(b.approval) ? 1 : -1;
    };
    const filterDate = (a) => Date.parse(a) != NaN;
    const unique = (data) => [...new Set(data)];

    const drugNames = unique(
        data
            ?.map((drug) => drug.name)
            .filter(filter)
            .sort(sort),
    );

    const companyNames = unique(
        data
            ?.map((drug) => drug.manufacturer)
            .filter(filter)
            .sort(sort),
    );

    const areasOfTreatment = unique(
        data
            ?.map((drug) => drug.treatment)
            .filter(filter)
            .sort(sort),
    );

    const latestApprovals = unique(
        data
            .filter(filterDate)
            .sort(dateSort)
            .slice(0, 7)
            .map((drug) => {
                return {name: drug.name, url: drug.content_page_url};
            }),
    );

    const autocompleteData = {
        drugNames,
        companyNames,
        areasOfTreatment,
    };

    if (isMobile) {
        if (isShowSearch) {
            return (
                <div className={cx('search', 'mobile', 'center')}>
                    <h1>Drugs Media Content Directory for Press</h1>
                    <p>
                        Search for any FDA Approved Drug or Therapeutic since
                        2018
                    </p>
                    <SearchForm
                        form={form}
                        onSubmit={handleSubmit}
                        currentSearch={currentSearch}
                        autocompleteData={autocompleteData}
                        isLoading={isLoading}
                        isMobile
                    />
                    {latestApprovals.length > 0 && (
                        <Latest data={latestApprovals} />
                    )}
                    <p>
                        All content is official, up-to-date and approved for use
                        by Healthcare Media and Journalists
                    </p>
                </div>
            );
        }

        return (
            <div className={cx('search', 'mobile')}>
                <h1>Drugs Media Content Directory for Press</h1>
                <SearchForm
                    form={form}
                    onSubmit={handleSubmit}
                    currentSearch={currentSearch}
                    autocompleteData={autocompleteData}
                    isMobile
                />
                <SearchResults
                    currentSearch={currentSearch}
                    data={data}
                    isMobile
                />
            </div>
        );
    }

    if (isShowSearch) {
        return (
            <div className={cx('search', 'center')}>
                <h1>Drugs Media Content Directory for Press</h1>
                <p>
                    Search for any FDA Approved Drug or Therapeutic since 2018
                </p>
                <SearchForm
                    form={form}
                    onSubmit={handleSubmit}
                    currentSearch={currentSearch}
                    autocompleteData={autocompleteData}
                    isLoading={isLoading}
                />
                {latestApprovals.length > 0 && (
                    <Latest data={latestApprovals} />
                )}
                {isLoading ? (
                    <div className={cx('loading-carousel')} />
                ) : (
                    <DrugsCarousel />
                )}
                <p>
                    All content is official, up-to-date and approved for use by
                    Healthcare Media and Journalists
                </p>
                <RssFeed />
            </div>
        );
    }

    return (
        <div className={cx('search-results')}>
            <div>
                <h2>Your results for</h2>
                <SearchForm
                    form={form}
                    onSubmit={handleSubmit}
                    currentSearch={currentSearch}
                    autocompleteData={autocompleteData}
                    isHorizontal
                />
                <div className={cx('results')}>
                    {/* <Drugs /> */}
                    <SearchResults currentSearch={currentSearch} data={data} />
                </div>
                <GetInTouch />
            </div>
        </div>
    );
};

export default Search;
