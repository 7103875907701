import Carousel, {slidesToShowPlugin} from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import classNames from 'classnames/bind';
import data from 'data/carousel.json';
import React from 'react';
import styles from './DrugsCarousel.module.scss';

const cx = classNames.bind(styles);

// TODO: throws a console error in dev mode as documented here: https://github.com/brainhubeu/react-carousel/issues/689
const DrugsCarousel = () => {
    return (
        <div className={cx('drugs-carousel')}>
            <Carousel
                plugins={[
                    'infinite',
                    'arrows',
                    {
                        resolve: slidesToShowPlugin,
                        options: {
                            numberOfSlides: 5,
                        },
                    },
                ]}
            >
                {data.map((drug) => {
                    const image = '/carousel/' + drug.imageUrl;
                    return (
                        <a
                            key={drug.title}
                            className={cx('drug')}
                            href={drug.url}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={image} />
                            <div>
                                <div>{drug.title}</div>
                            </div>
                        </a>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default DrugsCarousel;
