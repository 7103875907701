import classNames from 'classnames/bind';
import {PropTypes} from 'prop-types';
import React from 'react';
import Result from './components/Result';
import ResultRow from './components/ResultRow';
import styles from './SearchResults.module.scss';

const cx = classNames.bind(styles);

const filterData = (currentSearch, data) => {
    let filteredData = data;
    const {name, company, areasOfTreatment, date} = currentSearch;

    if (name) {
        filteredData = filteredData.filter((row) => {
            if (!row.name) {
                return false;
            }
            return row.name.toLowerCase().indexOf(name.toLowerCase()) !== -1;
        });
    }

    if (company) {
        filteredData = filteredData.filter((row) => {
            if (!row.manufacturer) {
                return false;
            }
            return (
                row.manufacturer
                    .toLowerCase()
                    .indexOf(company.toLowerCase()) !== -1
            );
        });
    }

    if (areasOfTreatment) {
        filteredData = filteredData.filter((row) => {
            if (!row.treatment) {
                return false;
            }
            return (
                row.treatment
                    .toLowerCase()
                    .indexOf(areasOfTreatment.toLowerCase()) !== -1
            );
        });
    }

    if (date) {
        filteredData = filteredData.filter((row) => {
            if (!row.approval) {
                return false;
            }
            return row.approval.indexOf(date) !== -1;
        });
    }

    return filteredData;
};

const sortData = (data) => {
    return data.sort((a, b) => {
        if (!a.name) return 1;
        if (!b.name) return -1;
        return a.name.localeCompare(b.name);
    });
};

const SearchResults = ({currentSearch, data, isMobile}) => {
    const filteredData = filterData(currentSearch, data);
    const sortedData = sortData(filteredData);

    if (isMobile) {
        return (
            <div className={cx('search-results-mobile')}>
                {sortedData.map((result, index) => {
                    return <Result key={index} result={result} />;
                })}
            </div>
        );
    }

    return (
        <div className={cx('search-results')}>
            <div className={cx('results-header')}>
                <div>Name</div>
                <div>FDA Approval Date</div>
                <div>Manufacturer</div>
                <div>General Area of Treatment</div>
                <div>Type</div>
            </div>
            {sortedData.map((result, index) => {
                return <ResultRow key={index} result={result} />;
            })}
        </div>
    );
};

SearchResults.propTypes = {
    currentSearch: PropTypes.shape().isRequired,
    isMobile: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

SearchResults.defaultProps = {
    isMobile: false,
};

export default SearchResults;
