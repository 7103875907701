import queryString from 'query-string';
import {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

/**
 * Syncs the URL query params with the values in the search form.
 *
 * @param {object} form
 * @param {function} setCurrentSearch
 * @param {function} setShowSearch
 *
 * @return {function} updateParams
 */
function useUrlParams(form, setCurrentSearch, setShowSearch) {
    const location = useLocation();
    const history = useHistory();

    const updateForm = () => {
        const {a, c, d, n} = queryString.parse(location.search);
        const hasExistingParams = a || c || d || n;
        if (hasExistingParams) {
            const search = {
                areasOfTreatment: a,
                company: c,
                date: d,
                name: n,
            };
            form.reset(search);
            setCurrentSearch(search);
            setShowSearch(false);
        } else {
            const defaultSearch = {
                areasOfTreatment: '',
                company: '',
                date: '',
                name: '',
            };
            form.reset(defaultSearch);
            setCurrentSearch(defaultSearch);
        }
    };

    useEffect(() => {
        updateForm();
    }, [location.search]);

    const updateParams = (search) => {
        const {areasOfTreatment, company, date, name} = search;
        const parsedSearch = {
            a: areasOfTreatment?.trim(),
            c: company?.trim(),
            d: date?.trim(),
            n: name?.trim(),
        };
        const query = queryString.stringify(parsedSearch, {
            skipNull: true,
            skipEmptyString: true,
        });

        const parsedQuery = query === '' ? '' : `?${query}`;
        history.push(parsedQuery);
    };

    return {updateParams};
}

export default useUrlParams;
