import React, {useEffect, useState} from 'react';
import {format} from 'date-fns';
import {ReactComponent as RssIcon} from 'images/rss/rss.svg';
import classNames from 'classnames/bind';
import styles from './RssFeed.module.scss';

const cx = classNames.bind(styles);

const RssFeed = () => {
    const [{items, isLoading}, setQueryState] = useState({
        items: [],
        isLoading: true,
    });

    useEffect(() => {
        fetch('https://admin.isebox.net/notify/medscaperss')
            .then((response) => response.json())
            .then((result) => {
                setQueryState({
                    items: result.slice(0, 5),
                    isLoading: false,
                });
            });
    }, []);

    if (isLoading) {
        <div className={cx('feed')} />;
    }

    return (
        <div className={cx('feed')}>
            <div className={cx('content')}>
                <div className={cx('header')}>
                    <RssIcon />
                    <h3>Trending on MEDSCAPE</h3>
                </div>
                <div className={cx('items')}>
                    {items.map((item) => (
                        <a
                            key={item.title}
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div>
                                {format(new Date(item.isoDate), 'do MMM yyyy')}
                            </div>
                            <div>{item.title}</div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default RssFeed;
