import {Autocomplete} from '@material-ui/lab';
import classNames from 'classnames/bind';
import {PropTypes} from 'prop-types';
import React from 'react';
import {useWatch} from 'react-hook-form';
import styles from './InputRow.module.scss';

const cx = classNames.bind(styles);

const AutocompleteInput = ({
    edited,
    inputField,
    form,
    isHorizontalForm,
    data,
    currentValue,
    handleKeyUp,
}) => {
    const {setValue} = form;
    const {name, icon, editedIcon, placeholder} = inputField;

    const handleFilterOptions = (options, state) => {
        const {inputValue} = state;
        if (!edited || inputValue === '') {
            return [];
        }
        return options.filter((option) => {
            return option.toLowerCase().startsWith(inputValue.toLowerCase());
        });
    };

    const handleInputChange = (event, value) => {
        if (event) {
            setValue(name, value);
        }
    };

    const renderInput = (params) => {
        return (
            <div
                className={cx('input-row', {
                    edited,
                    'horizontal-form': isHorizontalForm,
                })}
                ref={params.InputProps.ref}
            >
                {edited ? editedIcon : icon}
                <input
                    {...params.inputProps}
                    placeholder={placeholder}
                    onMouseDownCapture={(e) => e.stopPropagation()}
                    onKeyUp={handleKeyUp}
                />
            </div>
        );
    };

    return (
        <Autocomplete
            freeSolo
            inputValue={currentValue ?? ''}
            options={data}
            openOnFocus={false}
            filterOptions={handleFilterOptions}
            onInputChange={handleInputChange}
            renderInput={renderInput}
        />
    );
};

AutocompleteInput.propTypes = {
    edited: PropTypes.bool.isRequired,
    inputField: PropTypes.shape().isRequired,
    form: PropTypes.shape().isRequired,
    isHorizontalForm: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentValue: PropTypes.string,
    handleKeyUp: PropTypes.func.isRequired,
};

const InputRow = ({
    inputField,
    form,
    isHorizontalForm,
    currentSearch,
    autocompleteData,
    onSubmit,
}) => {
    const {register, control, handleSubmit} = form;
    const {
        placeholder,
        name,
        icon,
        editedIcon,
        autocomplete,
        autocompleteField,
    } = inputField;
    const currentValue = useWatch({
        control,
        name,
        defaultValue: currentSearch[name],
    });

    const edited = !['', undefined].includes(currentValue);

    const handleKeyUp = (event) => {
        if (event.key === 'Enter') {
            handleSubmit((data) => {
                onSubmit(data);
            })();
        }
    };

    if (autocomplete) {
        const data = autocompleteData[autocompleteField];
        return (
            <AutocompleteInput
                inputField={inputField}
                edited={edited}
                form={form}
                isHorizontalForm={isHorizontalForm}
                data={data}
                currentValue={currentValue}
                handleKeyUp={handleKeyUp}
            />
        );
    }
    return (
        <div
            className={cx('input-row', {
                edited,
                'horizontal-form': isHorizontalForm,
            })}
        >
            {edited ? editedIcon : icon}
            <input
                onKeyUp={handleKeyUp}
                placeholder={placeholder}
                {...register(name)}
            />
        </div>
    );
};

InputRow.propTypes = {
    inputField: PropTypes.shape().isRequired,
    form: PropTypes.shape().isRequired,
    isHorizontalForm: PropTypes.bool.isRequired,
    currentSearch: PropTypes.shape().isRequired,
    autocompleteData: PropTypes.shape().isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default InputRow;
