import CircularProgress from '@material-ui/core/CircularProgress';
import classNames from 'classnames/bind';
import {ReactComponent as AreaGreyIcon} from 'images/search/area_grey.svg';
import {ReactComponent as AreaPurpleIcon} from 'images/search/area_purple.svg';
import {ReactComponent as CompanyGreyIcon} from 'images/search/company_grey.svg';
import {ReactComponent as CompanyPurpleIcon} from 'images/search/company_purple.svg';
import {ReactComponent as DateGreyIcon} from 'images/search/date_grey.svg';
import {ReactComponent as DatePurpleIcon} from 'images/search/date_purple.svg';
import {ReactComponent as NameGreyIcon} from 'images/search/name_grey.svg';
import {ReactComponent as NamePurpleIcon} from 'images/search/name_purple.svg';
import {ReactComponent as SearchIcon} from 'images/search/search.svg';
import {PropTypes} from 'prop-types';
import React from 'react';
import InputRow from './components/InputRow';
import styles from './SearchForm.module.scss';

const cx = classNames.bind(styles);

const inputFields = [
    {
        placeholder: 'Name (or click purple search bar to see all drugs)',
        name: 'name',
        icon: <NameGreyIcon />,
        editedIcon: <NamePurpleIcon />,
        autocomplete: true,
        autocompleteField: 'drugNames',
    },
    {
        placeholder: 'Company',
        name: 'company',
        icon: <CompanyGreyIcon />,
        editedIcon: <CompanyPurpleIcon />,
        autocomplete: true,
        autocompleteField: 'companyNames',
    },
    {
        placeholder: 'Areas of treatment',
        name: 'areasOfTreatment',
        icon: <AreaGreyIcon />,
        editedIcon: <AreaPurpleIcon />,
        autocomplete: true,
        autocompleteField: 'areasOfTreatment',
    },
    {
        placeholder: 'Date',
        name: 'date',
        icon: <DateGreyIcon />,
        editedIcon: <DatePurpleIcon />,
        autocomplete: false,
        autocompleteField: null,
    },
];

const SearchForm = ({
    isHorizontal,
    isMobile,
    onSubmit,
    form,
    currentSearch,
    autocompleteData,
    isLoading,
}) => {
    if (isLoading) {
        return (
            <div className={cx('search-form', 'loading', {mobile: isMobile})}>
                <CircularProgress size={isMobile ? 20 : 40} color="primary" />
            </div>
        );
    }

    const {handleSubmit} = form;

    const submit = (data) => {
        onSubmit(data);
    };

    return (
        <div
            className={cx('search-form', {
                horizontal: isHorizontal,
                mobile: isMobile,
            })}
        >
            <form>
                <div className={cx('form')}>
                    {inputFields.map((inputField) => (
                        <div key={inputField.name}>
                            <InputRow
                                inputField={inputField}
                                form={form}
                                currentSearch={currentSearch}
                                isHorizontalForm={isHorizontal}
                                autocompleteData={autocompleteData}
                                onSubmit={onSubmit}
                            />
                        </div>
                    ))}
                </div>
                <div className={cx('button')} onClick={handleSubmit(submit)}>
                    {isMobile ? 'Search' : <SearchIcon />}
                </div>
            </form>
        </div>
    );
};

SearchForm.propTypes = {
    isHorizontal: PropTypes.bool,
    isMobile: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    form: PropTypes.shape().isRequired,
    currentSearch: PropTypes.shape().isRequired,
    autocompleteData: PropTypes.shape().isRequired,
    isLoading: PropTypes.bool,
};

SearchForm.defaultProps = {
    isHorizontal: false,
    isMobile: false,
    isLoading: false,
};

export default SearchForm;
