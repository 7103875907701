import React from 'react';
import styles from './Contact.module.scss';
import classNames from 'classnames/bind';
import ContactForm from '../components/ContactForm';

const cx = classNames.bind(styles);

const Contact = () => {
    return <div className={cx('contact', 'center')}>
        <h1>Med-Vue provides only approved content from Drugs companies to Healthcare Journalists.<br/>
            If you have any questions - please get in touch</h1>
        <ContactForm></ContactForm>
    </div>;
};

export default Contact;
