import classNames from 'classnames/bind';
import React from 'react';
import styles from './Footer.module.scss';

const cx = classNames.bind(styles);

const Footer = () => {
    const today = new Date();
    const year = today.getFullYear();

    return (
        <footer>
            <div className={cx('links')}>
                <div>&copy; Med-Vue {year}</div>
                <div>
                    <a href={'https://isebox.com/terms-conditions'} target={'_blank'} rel={'noreferrer'}>
                        Terms &amp; Conditions
                    </a>
                </div>
                <div>
                    <a href={'https://isebox.com/privacy'} target={'_blank'} rel={'noreferrer'}>Privacy Policy</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
