import React, {Component} from 'react';
import axios from 'axios';
import styles from './ContactForm.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const initialState = {
    name: '',
    email: '',
    message: '',
    honeypot: '',
    phone: '',
    status: 'Submit',
    sent: false,
};

class ContactForm extends Component {
    constructor() {
        super();
        this.state = initialState;
    }

    handleChange(event) {
        const field = event.target.id;
        if (field === 'name') {
            this.setState({name: event.target.value});
        } else if (field === 'honeypot') {
            this.setState({honeypot: event.target.value});
        } else if (field === 'message') {
            this.setState({message: event.target.value});
        } else if (field === 'phone') {
            this.setState({phone: event.target.value});
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({status: 'Sending'});
        const url = SERVER_URL + '/notify/medvueContact?password=sdaf34asdf';
        axios({
            method: 'POST',
            url: url,
            data: this.state,
        }).then((response) => {
            if (response.data.status === 'sent') {
                this.setState(initialState);
                this.setState({sent: true});
            } else if (response.data.status === 'failed') {
                alert('Message Failed');
            }
        });
    }

    render() {
        const buttonText = this.state.status;
        return (
            <form onSubmit={this.handleSubmit.bind(this)} method="POST" autoComplete="off">
                <div className={cx('contact-form')}>
                    <div className={cx('input-row')}>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            value={this.state.name}
                            onChange={this.handleChange.bind(this)}
                            required
                        />
                    </div>
                    <div className={cx('input-row')}>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="honeypot"
                            value={this.state.honeypot}
                            onChange={this.handleChange.bind(this)}
                            required
                        />
                        <input type="email" id="email" name="email" style={{display: 'none'}}/>
                    </div>
                    <div className={cx('input-row')}>
                        <label htmlFor="phone">Phone:</label>
                        <input
                            type="phone"
                            id="phone"
                            value={this.state.phone}
                            onChange={this.handleChange.bind(this)}
                            required
                        />
                    </div>
                    <div className={cx('input-row')}>
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            value={this.state.message}
                            onChange={this.handleChange.bind(this)}
                            required
                        />
                    </div>
                    {
                        (this.state.sent) ?
                            (<span>Thank you for contacting us</span>) :
                            (<button type="submit">{buttonText}</button>)
                    }
                </div>
            </form>
        );
    }
}

export default ContactForm;
