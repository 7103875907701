import classNames from 'classnames/bind';
import React from 'react';
import {PropTypes} from 'prop-types';
import styles from './Latest.module.scss';

const cx = classNames.bind(styles);

const Latest = ({data}) => {
    return (
        <div className={cx('latest-approvals')}>
            <span>Latest FDA approvals: </span>
            {data.map((item, i) => (
                <a
                    href={item.url}
                    target={'_blank'}
                    key={item.name}
                    rel={'noreferrer'}
                >
                    {item.name}
                    {i < data.length - 1 ? <span>, </span> : <span />}
                </a>
            ))}
        </div>
    );
};

Latest.propTypes = {
    data: PropTypes.array.isRequired,
};

export default Latest;
